import { createEditorsJS, randomStr } from '../../utils'

export default class ClausesGroup {
    /**
     * Notify core that read-only mode is supported
     *
     * @returns {boolean}
     */
    static get isReadOnlySupported () {
        return true
    }

    static get helpIcon () {
        return '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
    }

    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @returns {{icon: string, title: string}}
     */
    static get toolbox () {
        return {
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            title: 'مجموعة بنود'
        }
    }

    constructor ({ api, data }) {
        this.api = api
        this.btn = null
        this.node = null
        this.group = data.group || null
        this.clause = this.group ? this.group.clause_id : null
        this.required = this.group && typeof this.group.required !== 'undefined' ? this.group.required : true
        this.editors = []
    }

    render () {
        const node = document.createElement('DIV')
        node.classList.add(...'clauses-group my-4 border-b-2 border-slate-100 p-2 transition-all'.split(' '))
        node.dataset.editor = 'true'
        node.id = randomStr(20)
        this.node = node
        this.node._clausesGroup = this

        const clause = this.group ? this.group.clauses.find(c => c.id === this.clause) : null

        if (clause) {
            this.node.dataset.groupId = this.group.id
            this.loadClause(clause)
            return node
        }

        const btn = document.createElement('BUTTON')
        btn.textContent = 'اختيار مجموعة بنود'
        btn.classList.add(this.api.styles.button, 'w-full')

        node.append(btn)

        this.btn = btn

        this.btn.addEventListener('click', () => {
            this.btn.dispatchEvent(
                new CustomEvent('choose-clauses-group', {
                    bubbles: true,
                    detail: { notify: this.notify.bind(this) }
                })
            )
        })

        return node
    }

    save () {
        return this.group ? {
            group: this.group.id,
            clause: this.clause,
            required: this.required
        } : {}
    }

    /**
     * Returns plugin settings
     *
     * @returns {Array}
     */
    renderSettings () {
        const settings = []
        if (!this.group) {
            return settings
        }

        settings.push({
            label: 'بند اختياري',
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            isActive: !this.required,
            closeOnActivate: true,
            toggle: true,
            onActivate: () => {
                this.required = !this.required

                this.applyRequiredSetting()
            }
        })

        for (let i = 0; i < this.group.clauses.length; i++) {
            const clause = this.group.clauses[i]
            const number = i + 1

            settings.push({
                label: `${number}- ${clause.name}`,
                icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 12.0001L11.6422 16.8212C11.7734 16.8868 11.839 16.9196 11.9078 16.9325C11.9687 16.9439 12.0313 16.9439 12.0922 16.9325C12.161 16.9196 12.2266 16.8868 12.3578 16.8212L22 12.0001M2 17.0001L11.6422 21.8212C11.7734 21.8868 11.839 21.9196 11.9078 21.9325C11.9687 21.9439 12.0313 21.9439 12.0922 21.9325C12.161 21.9196 12.2266 21.8868 12.3578 21.8212L22 17.0001M2 7.00006L11.6422 2.17895C11.7734 2.11336 11.839 2.08056 11.9078 2.06766C11.9687 2.05622 12.0313 2.05622 12.0922 2.06766C12.161 2.08056 12.2266 2.11336 12.3578 2.17895L22 7.00006L12.3578 11.8212C12.2266 11.8868 12.161 11.9196 12.0922 11.9325C12.0313 11.9439 11.9687 11.9439 11.9078 11.9325C11.839 11.9196 11.7734 11.8868 11.6422 11.8212L2 7.00006Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                isActive: this.clause === clause.id,
                closeOnActivate: true,
                toggle: true,
                onActivate: () => {
                    this.clause = clause.id
                    this.loadClause(clause)
                }
            })
        }

        return settings
    }

    requiredClass () {
        return 'required-clauses-group'
    }

    applyRequiredSetting () {
        if (!this.required) {
            this.node.classList.add(this.requiredClass())
        } else {
            this.node.classList.remove(this.requiredClass())
        }
    }

    notify (group) {
        window.axios.get(window.route('clauseGroupTemplates@show', [group]))
            .then(({ data }) => {
                this.group = data
                this.node.dataset.groupId = data.id
                this.loadClause(this.group.clauses[0])
            }).catch((e) => {
                console.log(e)
            })
    }

    loadClause (clause) {
        this.node.dispatchEvent(
            new CustomEvent('clauses-group-destroy', { bubbles: true, detail: { node: this.node } })
        )

        if (this.editors.length) {
            for (const editor of this.editors) {
                editor.destroy()
            }
        }

        this.node.innerHTML = ''
        this.node.dataset.clauseId = clause.id
        this.clause = clause.id

        setTimeout(() => {
            this.editors = createEditorsJS(this.node.id, clause.content, {
                readOnly: true,
                minHeight: 0
            }, () => {
                this.node.dispatchEvent(
                    new CustomEvent('clauses-group-changed', { bubbles: true, detail: { node: this.node } })
                )

                this.applyRequiredSetting()
            }, null, {})
        }, 10)
    }
}
