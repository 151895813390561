import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=7d4020ad&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d4020ad",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/var/www/qanoniah.com/components/common/Logo.vue').default,Icon: require('/var/www/qanoniah.com/components/common/Icon.vue').default,Tippy: require('/var/www/qanoniah.com/components/common/Tippy.vue').default})
