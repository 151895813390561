
    import Logo from '../components/common/Logo.vue'

    export default {
        components:{
            Logo
        },

        beforeMount(){
            document.body.style.backgroundColor = '#F8FAFC';
        }
    }
