
    export default {

        mounted() {
            document.body.style.overflow = 'hidden'
        },

        destroyed() {
            document.body.style.overflow = 'visible'
        },
    }
