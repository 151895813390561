
import Icon from '~/components/UntitledUI/Icon.vue'
import Tippy from '~/components/common/Tippy.vue'
import Dot from '~/components/UntitledUI/Dot.vue'

export default {
    name: 'SideBar',
    components: { Dot, Icon, Tippy },
    props: {
        isExpanded: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            expanded: this.isExpanded,
            sideLinks: [
                {
                    to: ['/GettingStarted', '/Search'],
                    icon: 'home-02',
                    label: 'الرئيسية'
                },
                {
                    rule: '',
                    to: '/Contracts',
                    icon: 'documents',
                    label: 'عقود ونماذج',
                    feature: 'contracts'
                },
                {
                    to: '/HighlightsAndNotes',
                    icon: 'marker',
                    label: 'تحديداتي وملاحظاتي'
                },
                {
                    to: '/Folders',
                    icon: 'opened-folder',
                    label: 'مجلداتي'
                },
                {
                    to: '/AI/Chat',
                    icon: 'AI',
                    label: 'الذكاء الاصطناعي',
                    feature: 'gpt'
                },
                {
                    rule: '',
                    to: '/Workers',
                    icon: 'users-01',
                    label: 'العاملين',
                    feature: 'contracts'
                },
                {
                    rule: '',
                    to: '/Subscriptions',
                    icon: 'clip-board',
                    label: 'الاشتراكات'
                },
                {
                    rule: '',
                    to: '/Settings',
                    icon: 'setting',
                    label: 'الإعدادات'
                }
            ]
        }
    },
    computed: {
        links () {
            return this.sideLinks.filter(l => this.$can(l.feature))
        }
    },
    watch: {
        isExpanded (val) {
            this.expanded = val
        }
    },
    methods: {
        expand () {
            this.expanded = true
        },
        shrink () {
            this.expanded = false
        },
        toggle () {
            this.expanded = !this.expanded
        }
    }
}
