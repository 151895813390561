
    import * as components from './Components'

    export default {
        name: 'Doc',
        props:{
            doc: Object,
            size: {
                type: [String],
                required: false,
                default: 'lg',
            }
        },
        components:{
            ...components
        },

        methods: {
            getType(type){
                return type.charAt(0).toUpperCase() + type.slice(1);
            }
        },
    }
