import Echo from 'laravel-echo'

export default {
    // loading
    // success - have results
    // success - no results
    // fail
    data () {
        return {
            isUnread: false,
            onlyCheck: false,
            type: undefined,
            hasUnreadNotifications: false,
            canLoadMoreNotifications: true,
            notifications: null,
            notificationForm: new this.$vForm({
                last_id: undefined,
                is_unread: undefined,
                type: undefined,
                order: undefined
            })
        }
    },
    computed: {
        sortedNotifications () {
            if (!this.notifications) {
                return []
            }

            return Object.values(this.notifications).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        },
        lastNotification () {
            return this.sortedNotifications.length ? this.sortedNotifications.at(-1) : undefined
        }
    },
    mounted () {
        this.initRealtime()
        this.initNotifications()
    },
    destroyed () {
        this.notificationsReset()
    },
    methods: {
        initRealtime () {
            this.initEcho()
            this.listenForNotifications()
        },
        initEcho () {
            if (window.Echo && ['connected', 'connecting', 'initialized'].includes(window.Echo.connector.pusher.connection.state)) {
                return
            }
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.ABLY_PUBLIC_KEY,
                wsHost: 'realtime-pusher.ably.io',
                disableStats: true,
                encrypted: true,
                forceTLS: true,
                cluster: 'eu',
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$axios.post('/v1/broadcasting/auth', {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then((response) => {
                                    callback(null, response.data)
                                })
                                .catch((error) => {
                                    callback(error)
                                })
                        }
                    }
                }
            })
        },
        listenForNotifications () {
            window.Echo.private(`Tenant.${this.$auth.user.tenant_id}.User.${this.$auth.user.id}`)
                .listen('.DatabaseNotificationCreated', this.addRealtimeNotification)
        },
        addRealtimeNotification (notification) {
            this.hasUnreadNotifications = true
            if (this.notifications) {
                this.$set(this.notifications, notification.id, notification)
            }
        },
        async fetchNotifications () {
            if (this.notificationForm.busy) {
                return
            }

            this.notificationForm.last_id = this.lastNotification?.id
            this.notificationForm.type = this.type
            this.notificationForm.is_unread = this.isUnread ? 1 : 0
            this.notificationForm.only_check = this.onlyCheck ? 1 : 0

            await this.notificationForm.get('/v1/notifications')
                .then((res) => {
                    const { notifications, hasUnreadNotifications } = res.data
                    this.hasUnreadNotifications = hasUnreadNotifications
                    if (!this.notifications) {
                        this.notifications = {}
                    }
                    notifications.forEach((notification) => {
                        this.$set(this.notifications, notification.id, notification)
                    })

                    if (!notifications || notifications.length < 10) {
                        this.canLoadMoreNotifications = false
                    }
                })
                .catch(() => {
                    this.canLoadMoreNotifications = false
                    this.notifications = false
                })
        },
        initNotifications () {
            this.notificationsReset()
            this.fetchNotifications()
        },
        notificationsReset () {
            this.canLoadMoreNotifications = true
            this.notifications = null
        },
        async loadMore () {
            await this.fetchNotifications()
        },
        canLoadMore () {
            return !this.notificationForm.busy && this.canLoadMoreNotifications
        }
    }
}
