import Cookies from 'universal-cookie'

export default function ({ $axios }) {
    $axios.onResponse((response) => {
        const cookies = new Cookies()
        const clientVersion = cookies.get('client-version')
        const appVersion = cookies.get('app-version')

        cookies.set('client-version', appVersion)

        if (clientVersion !== undefined && clientVersion !== appVersion) {
            // TODO find whats the problem when in iframe
            // window.location.reload(true)
        }
    })

    $axios.$get('/v1/sanctum/csrf-cookie')
}
