import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

export const state = () => ({
    notifications: null,
    page: 1,
    isUnread: false,
    loading: true
})

export const mutations = {
    setHasUnreadNotifications (state, hasUnread) {
        const user = this.$auth.user
        user.has_unread_notifications = hasUnread
        this.$auth.setUser(user)
    },
    setNotifications (state, notifications) {
        state.notifications = notifications
    },
    setLoading (state, isLoading) {
        state.loading = isLoading
    },
    setPage (state, page) {
        state.page = page
    },
    setIsUnread (state, isUnread) {
        state.isUnread = isUnread
    }
}

export const actions = {
    init ({ state, commit }) {
        if (!this.$auth.loggedIn || !process.env.ABLY_PUBLIC_KEY) {
            return
        }
        window.Pusher = Pusher

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.ABLY_PUBLIC_KEY,
            wsHost: 'realtime-pusher.ably.io',
            disableStats: true,
            encrypted: true,
            forceTLS: true,
            cluster: 'eu',
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        this.$axios.post('/v1/broadcasting/auth', {
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                            .then((response) => {
                                callback(null, response.data)
                            })
                            .catch((error) => {
                                callback(error)
                            })
                    }
                }
            }
        })
        window.Echo.private(`Tenant.${this.$auth.user.tenant_id}.User.${this.$auth.user.id}`)
            .listen('.DatabaseNotificationCreated', (notification) => {
                commit('setHasUnreadNotifications', true)
                if ('type' in notification &&
                    typeof notification.type === 'string' &&
                    ['success', 'warning', 'info', 'error'].includes(notification.type) &&
                    'title' in notification &&
                    typeof notification.title === 'string'
                ) {
                    this.$toast[notification.type]({
                        component: Notification,
                        props: {
                            text: notification.title
                        }
                    })
                }

                if (state.notifications !== null) {
                    const notifications = Object.assign({}, state.notifications)
                    notifications.data = [notification, ...notifications.data]
                    commit('setNotifications', notifications)
                }
            })
    },
    destroy ({ state }) {
        window.Echo?.leave(`Tenant.${this.$auth.user.last_tenant_id}.User.${this.$auth.user.id}`)
    },
    fetchNotifications ({ state, commit }, { page = 1, isUnread = false }) {
        commit('setLoading', true)
        commit('setIsUnread', isUnread)
        commit('setPage', page)
        this.$axios.$get('/v1/notifications', {
            params: {
                page: state.page,
                isUnread: state.isUnread ? 1 : 0
            }
        })
            .then((res) => {
                const { notifications, hasUnreadNotifications } = res

                commit('setNotifications', notifications)
                commit('setHasUnreadNotifications', hasUnreadNotifications)
                commit('setLoading', false)
            })
            .catch(_ => commit('setLoading', false))
    }
}
