
export default {
  auth: false,
  computed: {
    links () {
      return [
        { label: 'رسالتنا', to: '/NationalDay/#our_message' },
        { label: 'مميزات قانونية', to: '/NationalDay/#features' },
        { label: 'شركاء النجاح', to: '/NationalDay/#success_partners' },
      ]
    }
  },
  data () {
    return {
      windowHeight: null
    }
  },
  mounted () {
    this.windowHeight = (window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight) - this.$refs.header.$el.offsetHeight - this.$refs.footer.$el.offsetHeight
  }
}
