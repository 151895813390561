
import Logo from '@/components/common/Logo.vue'

export default {
    components: { Logo },
    data () {
        return {
            lastScroll: 0,
            scrollingDown: false,
            scrollTimeout: null,
            stopScrollHandle: false
        }
    },
    watch: {
        scrollingDown () {
            this.$helpers.eventBus.$emit('header-bar', {
                dir: this.scrollingDown ? 'down' : 'up',
                height: this.$refs.headerBar ? this.$refs.headerBar.clientHeight : 0
            })
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll)
        this.$helpers.eventBus.$emit('header-mounted', this.$refs.headerBar.clientHeight)
        this.$helpers.eventBus.$on('stopScrollHandle', (val = true) => {
            this.stopScrollHandle = val
        })
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll)
        this.$helpers.eventBus.$off('stopScrollHandle')
    },
    methods: {
        handleScroll () {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout)
                this.scrollTimeout = null
            }
            if (this.stopScrollHandle) {
                this.lastScroll = window.pageYOffset
                return
            }

            this.scrollTimeout = setTimeout(() => {
                if (!this.$refs.headerBar) {
                    this.scrollingDown = false
                    return
                }
                const currentScroll = window.pageYOffset
                if (currentScroll > this.lastScroll) {
                    this.scrollingDown = true
                } else {
                    this.scrollingDown = false
                }
                this.lastScroll = currentScroll
            }, 50)
        }
    }
}
