export const state = () => ({
    results: null,
    suggestion: null,
    history: null,
    flowMaps: null
})

export const mutations = {
    setResults (state, payload) {
        state.results = payload
    },

    setHistory (state, payload) {
        state.history = payload
    },

    deleteHistory (state, { type, history }) {
        if (!(type in state.history)) {
            return
        }

        const h = state.history
        h[type] = h[type].filter(c => c.id !== history.id)

        state.history = h
    },

    setSuggestion (state, payload) {
        state.suggestion = payload
    },

    addSuggestion (state, payload) {
        if (state.suggestion !== null) {
            if (isExisting(state.suggestion, payload)) {
                state.suggestion.splice(state.suggestion.findIndex(f => f.hash === payload.hash), 1)
                state.suggestion.unshift(payload)
                return
            }
            state.suggestion.unshift(payload)
        }
    },

    setFlowMaps (state, payload) {
        state.flowMaps = payload
    },

    resetAll (state) {
        Object.keys(state).forEach(k => state[k] = null)
    }
}

function isExisting (templates, template) {
    return templates.some(t => t.id === template.id)
}
