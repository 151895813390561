
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'NotificationItem',
    components: {
        Icon
    },
    props: {
        notification: Object,
        type: {
            type: String,
            default: 'read_at'
        }
    },
    computed: {
        isRead () {
            return this.notification[this.type] !== null
        }
    },
    methods: {
        canHandleAction (action) {
            if (!action || !action.type || !action.title) {
                return false
            }

            return [
                'redirect',
                'redirect-home',
                'redirect-to-chat-room'
            ].includes(action.type)
        },
        handleAction (action) {
            if (action.type === 'redirect-home') {
                return '/'
            }
            if (action.type === 'redirect') {
                const url = action.data.url

                const startIndex = url.indexOf(window.location.origin)
                return startIndex === -1
                    ? url
                    : url.slice(startIndex + window.location.origin.length)
            }
            if (action.type === 'redirect-to-chat-room') {
                return '/File/' + action.data.fileHash + '?chatRoom=' + action.data.chatRoomId
            }
        }
    }
}
