
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

export default {
    data () {
        return {
            modal: null
        }
    }
}
