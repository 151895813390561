export const state = () => ({
    trash: false,
    search: false,
    loading: true,
    selectedNodes: [],
    hasDragStarted: false,
    roots: [],
    activeRoot: null,
    folder: null
})

export const getters = {
    getFolder: (state) => {
        return state.folder
    },
    isTrash: (state) => {
        return state.trash
    },
    searchText: (state) => {
        return state.search
    },
    isLoading: (state) => {
        return state.loading
    },
    selectedNodes: (state) => {
        return state.selectedNodes
    },
    hasDragStarted: (state) => {
        return state.hasDragStarted
    },
    getRootFolders: (state) => {
        return state.roots
    },
    getActiveRootFolder: (state) => {
        return state.activeRoot
    }
}

export const mutations = {
    setFolder (state, payload) {
        state.folder = payload
        state.trash = state.folder.id === 'trash'
        if (state.trash) {
            state.activeRoot = state.folder
            return
        }
        for (const root of state.roots) {
            if (state.folder?.path && state.folder?.path?.length > 0 && state.folder.path.find(p => p.id === root?.id) !== undefined) {
                state.activeRoot = root
                break
            }
            if (state.folder?.id && state.folder?.id === root?.id) {
                state.activeRoot = root
                break
            }
        }
    },
    setSearchText (state, payload) {
        state.search = payload
    },
    setIsLoading (state, payload) {
        state.loading = payload
    },
    addSelectedNode (state, payload) {
        if (!state.selectedNodes.includes(payload)) {
            state.selectedNodes.push(payload)
        }
    },
    removeSelectedNode (state, payload) {
        state.selectedNodes = state.selectedNodes.filter(n => n !== payload)
    },
    clearSelectedNodes (state) {
        state.selectedNodes = []
    },
    startDrag (state) {
        state.hasDragStarted = true
    },
    endDrag (state) {
        state.hasDragStarted = false
    },
    setRootFolders (state, payload) {
        state.roots = payload
    }
}
