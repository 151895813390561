
import {vInfiniteScroll} from '@vueuse/components'
import Icon from '@/components/UntitledUI/Icon.vue'
import NotificationItem from '@/components/Notifications/NotificationItem.vue'
import NotificationUtils from '@/mixins/Notifications/NotificationUtils'
import Tippy from '~/components/common/Tippy.vue'

export default {
    name: 'BellNotification',
    directives: {
        vInfiniteScroll
    },
    components: {
        NotificationItem,
        Icon,
        Tippy
    },
    mixins: [NotificationUtils],
    data () {
        return {
            isUnread: undefined,
            onlyCheck: true,
            type: 'read_at_top'
        }
    },
    methods: {
        tippyDestroyed () {
            this.onlyCheck = true
            this.notificationsReset()
        },
        tippyShow () {
            this.onlyCheck = false
            this.initNotifications()
        },
        handleNotificationItemClick (e, n) {
            if (e.target.nodeName !== 'A') {
                this.$router.push({ path: '/Notifications', query: { isUnread: n.read_at_top === null ? 1 : undefined } })
            }
            this.$refs.tippy.tippy.hide()
        }
    }
}
