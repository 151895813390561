import FeatureLimitExceeded from '@/components/common/FeatureLimits/FeatureLimitExceeded.vue'
import { copyComponent } from '@/Utils/copyComponent'

export default function ({ $axios, $auth, redirect, $toast, store, $gtm }) {
    // $axios.onRequest((request) => {
    //
    //     if (request.url === $auth.getStrategy().options.endpoints.login.url) {
    //         return;
    //     }
    //
    //     if (request.url === $auth.getStrategy().options.endpoints.user.url) {
    //         return;
    //     }
    //
    //     const ids = process.env.MAINTENANCE_USERS.split(',').map(id => parseInt(id))
    //
    //     if (process.env.MAINTENANCE && !ids.includes($auth.user?.id)) {
    //         redirect('/UnderMaintenance')
    //         return Promise.reject('Maintenance')
    //     }
    // })
    $axios.onError(async (error) => {
        if (error === 'Maintenance') {
            return Promise.reject(error)
        }

        const status = error?.response?.status
        if (status === undefined || status === null) {
            return Promise.reject(error)
        }

        // For Subscriptions & feature limit
        if (status === 403) {
            const feature = error?.response?.data?.feature ?? null
            let msg = error?.response?.data?.message || error?.response?.data || error?.response
            blob: if (typeof Blob !== 'undefined' && msg instanceof Blob) {
                if (msg.type === 'application/json') {
                    let blobData = await msg?.text() ?? msg
                    blobData = (typeof blobData === 'string') ? JSON.parse(blobData) : blobData
                    if (blobData !== null && typeof blobData === 'object') {
                        msg = blobData.message
                        break blob
                    }
                }
                const file = new FileReader()
                file.onload = () => {
                    $toast.error(file.result)
                }
                file.readAsText(msg)
            }
            if (typeof msg === 'string' && feature && typeof window !== 'undefined') {
                const child = copyComponent(FeatureLimitExceeded, {
                    propsData: {
                        feature, msg, $toast, $auth
                    }
                })
                window.document.body.appendChild(child.$el)
            }
            if (typeof msg === 'string' && $toast && !feature) {
                $toast.error(msg)
            }
            if (error?.response?.data?.isSubscribe === false) {
                redirect('/Subscriptions')
            }
            return Promise.reject(error)
        }

        if ($auth.loggedIn && status === 401) {
            store.commit('files/resetAll')
            $auth.logout()
            $gtm.push({
                event: 'logout',
                user_id: null
            })
            return Promise.reject(error)
        }

        if (status === 419) {
            if (!(typeof window === 'undefined')) { window.location.reload(true) }
            return Promise.reject(error)
        }

        return Promise.reject(error)
    })
}
