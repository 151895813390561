
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    components: {
        Icon
    },
    props: {
        links: Array,
        classes: String
    },
    data () {
        return {
            showMenu: false,
            openDropDown: false
        }
    },

    methods: {
        async logout () {
            this.openDropDown = false
            await this.$auth.logout()
        },

        profile () {
            this.openDropDown = false

            this.$router.push('/Profile')
        },

        payments () {
            this.openDropDown = false

            this.$router.push('/Subscriptions')
        },

        teams () {
            this.openDropDown = false

            this.$router.push('/Teams')
        }
    }
}
