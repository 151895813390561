export const state = () => ({
    reuslt: {},
    showTrial: true,
    pkg: null
  })

export const mutations = {
    setResult (state, payload) {
        state.result = payload
    },

    setShowTrial (state, payload) {
        state.showTrial = payload
    },

    setPackage(state, payload) {
        state.pkg = payload
    }
}
