import Vue from 'vue'

export default ({ redirect, store, context, app }, inject) => {
    const metaData = ({ title = undefined, description = undefined, url = undefined }) => {
        const head = { meta: [] }
        if (description) {
            head.meta = [
                ...head.meta,
                {
                    hid: 'description',
                    name: 'description',
                    content: description
                },
                {
                    hid: 'twitter:description',
                    name: 'twitter:description',
                    content: description
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description
                }
            ]
        }

        if (title) {
            head.title = title
            head.meta = [
                ...head.meta,
                {
                    hid: 'twitter:title',
                    name: 'twitter:title',
                    content: title
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title
                }
            ]
        }
        head.meta = [
            ...head.meta,
            {
                hid: 'og:url',
                property: 'og:url',
                content: process.env.PUBLIC_URL + (url ?? app.router.currentRoute.fullPath)
            }
        ]

        return head
    }
    const mainTagsColorClass = (color, isBackground = false) => {
        return {
            green: isBackground ? 'bg-green-500' : 'text-green-500',
            blue: isBackground ? 'bg-blue-500' : 'text-blue-500',
            yellow: isBackground ? 'bg-yellow-500' : 'text-yellow-500',
            red: isBackground ? 'bg-red-500' : 'text-red-500',
            orange: isBackground ? 'bg-orange-500' : 'text-orange-500',
            gray: isBackground ? 'bg-slate-500' : 'text-slate-500',
            purple: isBackground ? 'bg-purple-500' : 'text-purple-500'
        }[color] ?? (isBackground ? 'bg-slate-500' : 'text-slate-500')
    }

    const helpers = {
        eventBus: new Vue(),
        metaData,
        mainTagsColorClass
    }
    inject('helpers', helpers)
}
