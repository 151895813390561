
import HeaderBarYLLKSA from '../components/main/HeaderBarYLLKSA.vue'

export default {
  components: { HeaderBarYLLKSA },
  auth: false,
  data() {
    return {
      windowHeight: null
    }
  },
  mounted() {
    this.windowHeight = (window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight) - this.$refs.header.$el.offsetHeight - this.$refs.footer.$el.offsetHeight

  },

  computed: {
    links () {
      const links = {
        right: [
          // { label: 'لماذا قانونية', to: '/#WhyQanoniah', class:"text-gray-600" },
          // { label: 'مميزاتنا', to: '/#features', class:"text-gray-600" },
          // { label: 'عملائنا', to: '/#ourclients', class:"text-gray-600" }
        ],
        left: []
      }

      // links['left'].push({
      //   label: 'الاشتراك',
      //   to: '/ContactUs',
      //   class: "bg-primary-50 text-primary-700 p-2 rounded-md cursor-pointer"
      // })

      links['left'].push({
        label: 'تسجيل جديد',
        to: '#register',
        class: "bg-primary-600 hover:bg-primary-700 text-white p-2 rounded-md cursor-pointer"
      })

      links['left'].push({
        label: 'تسجيل الدخول',
        to: '/Login',
        class: "text-gray-600"
      })

      return links
    }
  }
}
