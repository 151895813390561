export const state = () => ({
    isAllStepsDone: false
})

export const getters = {
    isAllStepsDone: state => state.isAllStepsDone
}

export const mutations = {
    setIsAllStepsDone (state, payload) {
        state.isAllStepsDone = payload
    }
}
