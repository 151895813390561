import Vue from 'vue'

export default ({ store }, inject) => {
    Vue.directive('can', {
        inserted (el, binding) {
            if ([undefined, null].includes(binding.value)) {
                return
            }
            const hasFeature = Object.entries(store?.$auth?.user?.features ?? {}).find((feature) => {
                return feature[0] === binding.value && feature[1]
            })

            if (!hasFeature) {
                if (el.__vue__) {
                    el.__vue__.$destroy()
                }
                el.parentNode.removeChild(el)
            }
        }
    })
}
