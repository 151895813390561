import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default function ({ $auth }) {
    Sentry.init({
        Vue,
        dsn: process.env.SENTRY_DSN,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.5,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [new Sentry.Replay({
            maskAllText: false,
            maskAllInputs: true
        })],
        beforeSend (event, hint) {
            if ([401, 403, 404, 419, 422].includes(hint.originalException?.response?.status)) {
                return null
            }
            if (hint.originalException === 'Timeout') {
                return null
            }

            return event
        }
    })

    if ($auth && $auth.user && $auth.user.id) {
        Sentry.setUser({id: $auth.user.id, email: $auth.user.email})
    }
}
