
    import Loom from './Video/Loom.vue';
    import {default as LoomThumbnail} from './Thumbnail/Loom.vue';
    import VideoContainer from './VideoContainer.vue';
    import Doc from './TipTap/Doc.vue';

    export default {
        props: {
            article: Object,
            group: Object,
            section: Object,
            video: {
                type: [Object],
                required: false,
                default: null,
            },
            rating: {
                type: [Object],
                required: false,
                default: null,
            },

            size: {
                type: [String],
                required: false,
                default: 'lg',
            },

        },

        components:{
            Loom,
            LoomThumbnail,
            VideoContainer,
            Doc
        },

        data() {
            return {
                showVideo: false,
                ratingData: this.rating
            }
        },

        computed: {
            content(){
                return this.article?.content
                return this.article?.content.replace(/<p><\/p>/g, "<br />")
            }
        },

        methods: {
            async sendRating(thumbs){
                const { data } =  await this.$axios.post('v1/help-center-rating', {
                    article_id: this.article.id,
                    thumbs: thumbs
                });

                if(data.status === "SUCCESS"){
                    this.ratingData = {
                        thumbs,
                        comment: this.rating?.comment ?? null
                    }
                }

                return
            }
        },
    }
