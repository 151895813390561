import { LocalScheme } from '~auth/runtime'

export default class EmbedScheme extends LocalScheme {
    check (checkStatus) {
        return { valid: true }
    }

    login (endpoint) {
        return this.fetchUser(endpoint)
    }

    async fetchUser (endpoint) {
        // Token is required but not available
        if (!this.check().valid) {
            return
        }

        const $axios = this.$auth.ctx.$axios
        const $route = this.$auth.ctx.route

        $axios.defaults.headers.common.Authorization = `Bearer ${$route.params.token}`

        try {
            const user = await $axios.$get('/v1/user')
            this.$auth.setUser(user)
        } catch (error) {
            this.$auth.callOnError(error, { method: 'fetchUser' })
        }
    }
}
