export const state = () => ({
  options: null,
  filters: null,
  results: null,
  text: '',
  fileType: 'ALL'
})

export const getters = {
  filters: state => {
    if (state.filters === null) return null
    const temp = {}
    let allNull = true
    Object.keys(state.filters).forEach(k => {
      if (state.filters[k] !== null) {
        temp[k] = state.filters[k]
        allNull = false
      }
    })
    return allNull ? null : temp
  }
}

export const mutations = {
  setOptions (state, payload) {
    state.options = payload
  },
  setText (state, payload) {
    state.text = payload
  },
  setFileType (state, payload) {
    state.fileType = payload
  },
  setResults (state, payload) {
    state.results = payload
  },
  setFilters (state, payload) {
    state.filters = payload
  },
  updateFilter (state, { key, value }) {
    state.filters[key] = value
  },
  resetAll (state) {
    state.options = null
    state.filters = null
    state.results = null
    state.text = ''
    state.fileType = 'ALL'
  }
}
