
import Icon from '@/components/UntitledUI/Icon.vue'
import Logo from '../common/Logo.vue'

export default {
    components: { Logo, Icon },
    props: {
        links: Object,
        classes: String
    },
    data () {
        return {
            showMenu: false,
            openDropDown: false
        }
    },
    computed: {
        getLinks () {
            return [...this.links.right, ...this.links.left]
        }
    }
}
