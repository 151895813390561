export const state = () => ({
    source: null,
    firstLanding: null
})

export const mutations = {
    setSource (state, payload) {
        if (!state.source) {
            state.source = payload
        }
    },

    setFirstLanding (state, payload) {
        if (!state.firstLanding) {
            state.firstLanding = payload
        }
    }
}
