
import Icon from '@/components/UntitledUI/Icon.vue'
import Logo from '../common/Logo.vue'

export default {
    components: { Logo, Icon },
    props: {
        links: {
            type: Array,
            default: () => []
        },
        classes: String
    },
    data () {
        return {
            showMenu: false,
            openDropDown: false,
            lastScroll: 0,
            scrollingDown: false,
            scrollTimeout: null
        }
    },

    watch: {
        scrollingDown () {
            this.$helpers.eventBus.$emit('header-bar', {
                dir: this.scrollingDown ? 'down' : 'up',
                height: this.$refs.headerBar ? this.$refs.headerBar.clientHeight : 0
            })
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll)
        this.$emit('header-mounted', this.$refs.headerBar.clientHeight)
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll () {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout)
                this.scrollTimeout = null
            }

            this.scrollTimeout = setTimeout(() => {
                if (!this.$refs.headerBar) {
                    this.scrollingDown = false
                    return
                }
                const currentScroll = window.pageYOffset
                if (currentScroll <= this.$refs.headerBar.offsetHeight) {
                    this.scrollingDown = null
                    this.lastScroll = currentScroll
                    return
                }
                if (currentScroll > this.lastScroll) {
                    this.scrollingDown = true
                } else {
                    this.scrollingDown = false
                }
                this.lastScroll = currentScroll
            }, 50)
        }
    }
}
