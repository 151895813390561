export default class CellAlignment {
    static get isReadOnlySupported () {
        return true
    }

    static get isInline () {
        return true
    }

    constructor ({
        api,
        config
    }) {
        this.button = null
        this.state = false
        this.api = api
        this.defaultAlign = config.default || ((api.i18n.direction || 'ltr') === 'ltr' ? 'left' : 'right')
        this.align = config.align

        this.iconClasses = {
            base: this.api.styles.inlineToolButton,
            active: this.api.styles.inlineToolButtonActive,
        }
    }

    render () {
        this.button = document.createElement('button')
        this.button.type = 'button'
        this.button.classList.add(this.iconClasses.base)

        if (this.align === 'left') {
            this.button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 23h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m10 45h28c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>'
        } else if (this.align === 'center') {
            this.button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 23c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m46 45c1.104 0 2-.896 2-2s-.896-2-2-2h-28c-1.104 0-2 .896-2 2s.896 2 2 2z"/></svg>'
        } else if (this.align === 'right') {
            this.button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" id="Layer" enable-background="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20"><path d="m54 8h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 52h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 19h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 30h-44c-1.104 0-2 .896-2 2s.896 2 2 2h44c1.104 0 2-.896 2-2s-.896-2-2-2z"/><path d="m54 41h-28c-1.104 0-2 .896-2 2s.896 2 2 2h28c1.104 0 2-.896 2-2s-.896-2-2-2z"/></svg>'
        }

        return this.button
    }

    surround (range) {
        const cell = this.getCell(range)

        if (!cell) {
            return
        }

        cell.dataset.cellAlign = this.align
    }

    checkState (selection) {
        const cell = this.getCell(selection)

        if (!cell) {
            return
        }

        if ((cell.dataset.cellAlign || this.defaultAlign) === this.align) {
            this.button.classList.add(this.iconClasses.active)
        } else {
            this.button.classList.remove(this.iconClasses.active)
        }
    }

    getCell (from) {
        const text = from.anchorNode || from.commonAncestorContainer

        if (!text) {
            return
        }

        const anchorElement = text instanceof Element ? text : text.parentElement

        return anchorElement.closest('.tc-cell')
    }

    static hasExtraData (editor) {
        return {
            onReady (data) {
                if (data.extra) {
                    const extra = data.extra[CellAlignment.name]
                    for (const blockId in extra) {
                        const block = editor.blocks.getById(blockId)
                        if (block) {
                            const cells = block.holder.querySelectorAll('.tc-cell')
                            cells.forEach((cell, i) => cell.dataset.cellAlign = extra[blockId][i])
                        }
                    }
                }
            },
            afterSave () {
                const data = {}
                const blocksCount = editor.blocks.getBlocksCount()
                for (let i = 0; i < blocksCount; i++) {
                    const block = editor.blocks.getBlockByIndex(i)
                    if (block.name === 'table') {
                        data[block.id] = []
                        const cells = block.holder.querySelectorAll('.tc-cell')
                        cells.forEach((cell) => data[block.id].push(cell.dataset.cellAlign || ((editor.configuration.i18n.direction || 'ltr') === 'ltr' ? 'left' : 'right')))
                    }
                }
                return data
            },
        }
    }
}
