import tippyJs from 'tippy.js'

const stickyHeader = {
    bind: (el, binding, vnode) => {
        vnode.context.$helpers.eventBus.$on('header-bar', ({ dir, height }) => {
            let top = 10
            if (dir === 'up') {
                top += height
            }
            el.style.top = top + 'px'
        })
    },
    unbind: (el, binding, vnode) => {
        vnode.context.$helpers.eventBus.$off('header-bar')
    }
}

const tippy = {
    bind: (el, binding, vnode) => {
        tippyJs(el, typeof binding.value === 'string' ? { content: binding.value } : binding.value)
    }
}

const scrollIntoView = {
    inserted (el, binding, vnode, prevVnode) {
        el.scrollIntoView(binding.value ?? { behavior: 'smooth', block: 'center', inline: 'nearest' })
    }
}

export { stickyHeader, tippy, scrollIntoView }
