
  export default {
    props: {
      color: {
        type: String,
        default: '#08538A'
      },
      dir: {
        type: String,
        default: ''
      }
    }
  }
