import { render, staticRenderFns } from "./SearchFeatureLimitExceeded.vue?vue&type=template&id=57a00b34&"
import script from "./SearchFeatureLimitExceeded.vue?vue&type=script&lang=js&"
export * from "./SearchFeatureLimitExceeded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/var/www/qanoniah.com/components/common/Modal.vue').default})
