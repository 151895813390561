
export default {
    name: 'Avatar',
    props: {
        photo: {
            type: String,
            required: false
        },
        size: {
            type: String,
            required: false,
            default: () => 'sm',
            validator: val => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(val)
        },
        skeleton: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        prepareAvatarUrl () {
            if (!this.photo.includes('https://ui-avatars.com')) {
                return this.photo
            }
            const photoUrl = new URL(this.photo)

            photoUrl.searchParams.set('size', this.getAvatarImageSize(true))
            photoUrl.searchParams.set('background', 'F2F4F7')
            photoUrl.searchParams.set('color', '475467')
            photoUrl.searchParams.set('font-size', this.getAvatarFontSize())

            return photoUrl
        }
    },
    methods: {
        getAvatarImageSize (isPixel = false) {
            const pixelsOrClasses = isPixel ? 'pixel' : 'class'
            return {
                class: {
                    xs: 'w-6 h-6',
                    sm: 'w-8 h-8',
                    md: 'w-10 h-10',
                    lg: 'w-12 h-12',
                    xl: 'w-14 h-14',
                    '2xl': 'w-16 h-16'
                },
                pixel: {
                    xs: '24',
                    sm: '32',
                    md: '40',
                    lg: '48',
                    xl: '56',
                    '2xl': '64'
                }
            }[pixelsOrClasses][this.size]
        },
        getAvatarFontSize () {
            return {
                xs: '0.60',
                sm: '0.52',
                md: '0.44',
                lg: '0.40',
                xl: '0.40',
                '2xl': '0.40'
            }[this.size]
        }
    }
}
