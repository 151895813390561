export const state = () => ({
    currentFile: null,
    added: null,
    opened: null,
    noted: null,
    suggestion: null,
    history: null
})

export const mutations = {
    setCurrentFile(state, payload) {
        state.currentFile = payload
    },
    setAdded(state, payload) {
        state.added = payload
    },
    addAdded(state, payload) {
        if (state.added !== null && !isExisting(state.added, payload)) {
            if (isExisting(state.added, payload)) {
                state.added.splice(state.added.findIndex(f => f.hash === payload.hash), 1)
                state.added.unshift(payload)
                return
            }
            state.added.unshift(payload)
        }
    },
    setHistory(state, payload) {
        state.history = payload
    },
    deleteHistory(state, {type, history}) {
        if (!(type in state.history)) {
            return
        }

        const h = state.history
        h[type] = h[type].filter(c => c.id !== history.id)

        state.history = h
    },
    setOpened(state, payload) {
        state.opened = payload
    },
    addOpened(state, payload) {
        if (state.opened !== null) {
            if (isExisting(state.opened, payload)) {
                state.opened.splice(state.opened.findIndex(f => f.hash === payload.hash), 1)
                state.opened.unshift(payload)
                return
            }
            state.opened.unshift(payload)
        }
    },
    setNoted(state, payload) {
        state.noted = payload
    },
    addNoted(state, payload) {
        if (state.noted !== null && !isExisting(state.noted, payload)) {
            if (isExisting(state.noted, payload)) {
                state.noted.splice(state.noted.findIndex(f => f.hash === payload.hash), 1)
                state.noted.unshift(payload)
                return
            }
            state.noted.unshift(payload)
        }
    },

    setSuggestion(state, payload) {
        state.suggestion = payload
    },

    addSuggestion(state, payload) {
        if (state.suggestion !== null) {
            if (isExisting(state.suggestion, payload)) {
                state.suggestion.splice(state.suggestion.findIndex(f => f.hash === payload.hash), 1)
                state.suggestion.unshift(payload)
                return
            }
            state.suggestion.unshift(payload)
        }
    },

    resetAll(state) {
        Object.keys(state).forEach(k => state[k] = null)
    }
}

function isExisting(files, file) {
    return files.some(f => f.hash === file.hash)
}
