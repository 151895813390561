
export default {
    name: 'Dot',
    props: {
        size: {
            type: String,
            default: 'sm',
            validator: val => ['sm', 'md', 'lg'].includes(val)
        },
        outline: {
            type: Boolean,
            default: false
        }
    }
}
