import Header from '@editorjs/header'
import List from '@editorjs/list'
import Paragraph from 'editorjs-paragraph-with-alignment'
import Table from '@editorjs/table'
import EditorJS from '@editorjs/editorjs'
import CellAlignment from './tunes/CellTableAlignment'
import CellDir from './tunes/CellTableDirection'
import AlignmentBlockTune from './tunes/AlignmentBlockTune'
import ClausesGroup from './blocks/clausesGroup/ClausesGroup'
// import DragDrop from 'editorjs-drag-drop'
// import Undo from 'editorjs-undo'

const Messages = {
    ui: {
        blockTunes: {
            toggler: {
                'Click to tune': 'إضغط لمزيد من الخيارات',
                'or drag to move': 'أو قم بالسحب للتحريك'
            }
        },
        inlineToolbar: {
            converter: {
                'Convert to': 'تحويل إلى'
            }
        },
        toolbar: {
            toolbox: {
                Add: 'إضافة'
            }
        },
        popover: {
            Filter: 'بحث',
            'Nothing found': 'لا نتائج'
        }
    },
    toolNames: {
        Text: 'فقرة نصية',
        Heading: 'عنوان',
        List: 'قائمة',
        Table: 'جدول'
    },
    blockTunes: {
        delete: {
            Delete: 'حذف',
            'Click to delete': 'إضغط للحذف'
        },
        moveUp: { 'Move up': 'تحريك للأعلى' },
        moveDown: { 'Move down': 'تحريك للأسفل' }
    },
    tools: {
        table: {
            'Add column to left': 'إضافة عمود لليسار',
            'Add column to right': 'إضافة عمود لليمين',
            'Delete column': 'حذف العمود',
            'Add row above': 'إضافة صف بالأعلى',
            'Add row below': 'إضافة صف بالأسفل',
            'Delete row': 'حذف صف'
        }
    }
}

function editorJSTools (layout) {
    const align = layout === 'ltr' ? 'left' : 'right'

    return {
        header: {
            class: Header,
            tunes: ['alignment'],
            inlineToolbar: true
        },
        list: {
            class: List,
            tunes: ['alignment'],
            inlineToolbar: true
        },
        paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            config: {
                preserveBlank: true,
                defaultAlignment: align
            }
        },
        table: {
            class: Table,
            tunes: ['alignment'],
            inlineToolbar: ['bold', 'italic', 'link', 'محاذاة لليمين', 'محاذاة للوسط', 'محاذاة لليسار', 'اتجاه الكتابة لليمين', 'اتجاه الكتابة لليسار']
        },
        alignment: {
            class: AlignmentBlockTune,
            config: {
                default: align,
                blocks: {
                    header: 'center',
                    list: align
                }
            }
        },
        'محاذاة لليمين': {
            class: CellAlignment,
            config: {
                align: 'right',
                default: align
            }
        },
        'محاذاة لليسار': {
            class: CellAlignment,
            config: {
                align: 'left',
                default: align
            }
        },
        'محاذاة للوسط': {
            class: CellAlignment,
            config: {
                align: 'center',
                default: align
            }
        },
        'اتجاه الكتابة لليمين': {
            class: CellDir,
            config: {
                dir: 'rtl',
                default: layout
            }
        },
        'اتجاه الكتابة لليسار': {
            class: CellDir,
            config: {
                dir: 'ltr',
                default: layout
            }
        }
    }
}

function createEditorsJS (holder, data, config, onReady, onChange) {
    const editors = []

    const layout = data.length === 2 ? 'columns' : data[0].layout

    if (layout === 'columns') {
        var editor1 = document.createElement('DIV')
        editor1.dataset.editor = 'true'
        editor1.id = holder + 1
        var editor2 = document.createElement('DIV')
        editor2.dataset.editor = 'true'
        editor2.id = holder + 2
        document.getElementById(holder).append(editor1, editor2)
    }

    const _layout = layout === 'columns' ? 'rtl' : layout
    let _data = Array.isArray(data) ? (data[0] || {}) : data

    if (layout === 'columns') {
        holder = editor1.id
    }

    editors.push(createEditorJS(holder, _layout, _data, config, onReady, onChange))

    if (layout === 'columns') {
        holder = editor2.id
        _data = Array.isArray(data) ? (data[1] || {}) : data
        editors.push(createEditorJS(holder, 'ltr', _data, config, onReady, onChange))
    }

    return editors
}

function createEditorJS (holder, layout, data, config, onReady, onChange) {
    const placeholder = layout === 'ltr' ? 'Write here' : 'قم بالكتابة هنا'
    const configuration = Object.assign({
        holder,
        // placeholder,
        inlineToolbar: ['bold', 'italic', 'link'],
        logLevel: 'ERROR',
        i18n: {
            /**
             * Text direction
             */
            direction: layout,
            messages: Messages
        },
        sanitizer: {
            BR: true
        },
        tools: editorJSTools(layout),
        data,
        onReady: (...args) => {
            // new Undo({ editor })
            // new DragDrop(editor)

            if (typeof onReady === 'function') {
                onReady(editor, data, ...args)
            }

            const extras = {}

            for (const toolKey in editor.configuration.tools) {
                if (typeof editor.configuration.tools[toolKey].class && typeof editor.configuration.tools[toolKey].class.hasExtraData === 'function') {
                    extras[editor.configuration.tools[toolKey].class.name] = editor.configuration.tools[toolKey].class
                }
            }

            for (const toolName in extras) {
                extras[toolName].hasExtraData(editor).onReady(data)
            }
        },
        onChange: (api, event) => {
            if (typeof onChange === 'function') {
                onChange(api, event)
            }
        }
    }, config)

    const editor = new EditorJS(configuration)

    return editor
}

function randomStr (length = 10) {
    length = Number(length)
    const s = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    return Array(length).join().split(',').map(function () {
        return s.charAt(Math.floor(Math.random() * s.length))
    }).join('')
}

/**
 * @param {String} string
 */
function slugify (string) {
    const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const codes = string.split('').map(char => char.charCodeAt(0))
    return codes.map(code => alpha.charAt(code % alpha.length)).join('').replace(/\s+/g, '-')
}

export function saveCaretPosition (context) {
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)
    range.setStart(context, 0)
    const len = range.toString().length

    return function restore () {
        const pos = getTextNodeAtPosition(context, len)
        selection.removeAllRanges()
        const range = new Range()
        range.setStart(pos.node, pos.position)
        selection.addRange(range)
    }
}

function getTextNodeAtPosition (root, index) {
    const NODE_TYPE = NodeFilter.SHOW_TEXT
    const treeWalker = document.createTreeWalker(root, NODE_TYPE, function next (elem) {
        if (index > elem.textContent.length) {
            index -= elem.textContent.length
            return NodeFilter.FILTER_REJECT
        }
        return NodeFilter.FILTER_ACCEPT
    })
    const c = treeWalker.nextNode()
    return {
        node: c || root,
        position: index
    }
}

/**
 *
 * @param {HTMLElement} root
 * @param {Number} bracesType 1 for normal braces, 2 for curly braces and 3 for square braces
 * @param {Boolean} saveSelection
 * @param {function(id:String, text:String)} onHighlight
 */
function highlightSyntax (root, bracesType = 1, saveSelection = true, onHighlight = null) {
    const variables = {}
    const {
        removerRegex,
        cleanerRegex,
        adderRegex
    } = createHighlightSyntaxRegex(bracesType)
    const {
        adderReplacer,
        removerReplacer
    } = createHighlightSyntaxReplacers(bracesType, variables, onHighlight)
    const texts = root.querySelectorAll('[contenteditable]')
    let restore = null
    for (const text of texts) {
        if (saveSelection && text === document.activeElement) {
            restore = saveCaretPosition(document.activeElement)
        }
        const newHTML = text.innerHTML
            .replaceAll(removerRegex, removerReplacer)
            .replaceAll(cleanerRegex, '$<text>')
            .replaceAll(adderRegex, adderReplacer)
        if (newHTML !== text.innerHTML) {
            text.innerHTML = newHTML
        }
        if (saveSelection && text === document.activeElement && typeof restore === 'function') {
            restore()
        }
    }
}

/**
 *
 * @param {Number} bracesType 1 for normal braces, 2 for curly braces and 3 for square braces
 * @returns {{cleanerRegex: RegExp, removerRegex: RegExp, adderRegex: RegExp}}
 */
function createHighlightSyntaxRegex (bracesType = 1) {
    if (bracesType === 1) {
        return {
            removerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<pre>.*?)\(\((?<text>[^)(]+?)\)\)(?<post>.*?)<\/span>/gm,
            cleanerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<text>.*?)<\/span>/gm,
            adderRegex: /\(\((?<text>[^)(]+?)\)\)/gm
        }
    } else if (bracesType === 2) {
        return {
            removerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<pre>.*?)\{\{(?<text>[^}{]+?)\}\}(?<post>.*?)<\/span>/gm,
            cleanerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<text>.*?)<\/span>/gm,
            adderRegex: /\{\{(?<text>[^}{]+?)\}\}/gm
        }
    } else if (bracesType === 3) {
        return {
            removerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<pre>.*?)\[\[(?<text>[^\][]+?)\]\](?<post>.*?)<\/span>/gm,
            cleanerRegex: /<span [^>]*?data-editor-variable="(?<id>[^"]+?)"[^>]*?>(?<text>.*?)<\/span>/gm,
            adderRegex: /\[\[(?<text>[^\][]+?)\]\]/gm
        }
    } else {
        throw new Error(`unsupported brace option: ${bracesType}`)
    }
}

/**
 *
 * @param {Number} bracesType 1 for normal braces, 2 for curly braces and 3 for square braces
 * @param variables
 * @param onHighlight
 * @returns {{adderReplacer: ((function(*, *): (string|undefined))|*), removerReplacer: ((function(*, *, *, *, *): (string|undefined))|*)}}
 */
function createHighlightSyntaxReplacers (bracesType = 1, variables, onHighlight) {
    return {
        adderReplacer: (fullMatch, text) => {
            const id = variables[text] !== undefined ? text : randomStr(20)
            text = variables[text] !== undefined ? variables[text] : text
            if (typeof onHighlight === 'function') {
                onHighlight(id, text)
            }
            if (bracesType === 1) {
                return `<span data-editor-variable="${id}">((${text}))</span>`
            } else if (bracesType === 2) {
                return `<span data-editor-variable="${id}">{{${text}}}</span>`
            } else if (bracesType === 3) {
                return `<span data-editor-variable="${id}">[[${text}]]</span>`
            } else {
                throw new Error(`unsupported brace option: ${bracesType}`)
            }
        },
        removerReplacer: (fullMatch, id, pre, text, post) => {
            variables[id] = text
            if (bracesType === 1) {
                return `${pre}((${id}))${post}`
            } else if (bracesType === 2) {
                return `${pre}{{${id}}}${post}`
            } else if (bracesType === 3) {
                return `${pre}[[${id}]]${post}`
            } else {
                throw new Error(`unsupported brace option: ${bracesType}`)
            }
        }
    }
}

function focusElement (el, click = false, scroll = true) {
    if (scroll) {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        })
    }
    if (click) {
        el.click()
    }
    tryFocus(el, 0)
}

function tryFocus (el, tries) {
    el.focus()

    setTimeout(() => {
        if (document.activeElement !== el && tries < 10) {
            tryFocus(el, tries + 1)
        }
    }, 10)
}

export {
    editorJSTools,
    createEditorsJS,
    randomStr,
    slugify,
    highlightSyntax,
    createHighlightSyntaxRegex,
    createHighlightSyntaxReplacers,
    focusElement,
    EditorJS,
    Messages,
    ClausesGroup
}
