export default function (to, from, savedPosition) {
    try {
        if (savedPosition && (to?.hash === '#$wait' || from?.hash === '#$wait')) {
            return new Promise((resolve, reject) => {
                wait(this.app, resolve, savedPosition)
            })
        }
    } catch (e) {
        return false
    }
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}

function wait (app, resolve, savedPosition) {
    app.$helpers.eventBus.$once('scroll-behavior-wait-done', () => {
        resolve(savedPosition)
    })
}
