export default class CellDirection {
    static get isReadOnlySupported () {
        return true
    }

    static get isInline () {
        return true
    }

    constructor ({
        api,
        config
    }) {
        this.button = null
        this.api = api
        this.defaultDir = config.default || api.i18n.direction || 'ltr'
        this.dir = config.dir

        if (this.dir === undefined) {
            throw new Error('config.dir is required and must be a string with value of "ltr" or "rtl"')
        }

        this.iconClasses = {
            base: this.api.styles.inlineToolButton,
            active: this.api.styles.inlineToolButtonActive,
        }
    }

    render () {
        this.button = document.createElement('button')
        this.button.type = 'button'
        this.button.classList.add(this.iconClasses.base)

        if (this.dir === 'ltr') {
            this.button.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        } else {
            this.button.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 3V21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        }

        return this.button
    }

    surround (range) {
        const cell = this.getCell(range)

        if (!cell) {
            return
        }

        cell.dir = this.dir
    }

    checkState (selection) {
        const cell = this.getCell(selection)

        if (!cell) {
            return
        }

        if ((cell.dir || this.defaultDir) === this.dir) {
            this.button.classList.add(this.iconClasses.active)
        } else {
            this.button.classList.remove(this.iconClasses.active)
        }
    }

    getCell (from) {
        const text = from.anchorNode || from.commonAncestorContainer

        if (!text) {
            return
        }

        const anchorElement = text instanceof Element ? text : text.parentElement

        return anchorElement.closest('.tc-cell')
    }

    static hasExtraData (editor) {
        return {
            onReady (data) {
                if (data.extra) {
                    const extra = data.extra[CellDirection.name]
                    for (const blockId in extra) {
                        const block = editor.blocks.getById(blockId)
                        if (block) {
                            const cells = block.holder.querySelectorAll('.tc-cell')
                            cells.forEach((cell, i) => cell.dir = extra[blockId][i])
                        }
                    }
                }
            },
            afterSave () {
                const data = {}
                const blocksCount = editor.blocks.getBlocksCount()
                for (let i = 0; i < blocksCount; i++) {
                    const block = editor.blocks.getBlockByIndex(i)
                    if (block.name === 'table') {
                        data[block.id] = []
                        const cells = block.holder.querySelectorAll('.tc-cell')
                        cells.forEach((cell) => data[block.id].push(cell.dir || editor.configuration.i18n.direction || 'ltr'))
                    }
                }
                return data
            },
        }
    }
}
