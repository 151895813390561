import Vue from 'vue'

Vue.prototype.$can = function (feature) {
    if ([undefined, null].includes(feature)) {
        return true
    }
    return Object.entries(this.$auth?.user?.features ?? {}).some(([featureName, value]) => {
        return featureName === feature && value
    })
}
