
import Modal from '@/components/common/Modals/Modal.vue'
import Button from '@/components/UntitledUI/Button.vue'

export default {
    name: 'SearchFeatureLimitExceeded',
    components: { Modal, Button },
    data () {
        return {
            show: true
        }
    }
}
