
import SearchFeatureLimitExceeded from '@/components/common/FeatureLimits/SearchFeatureLimitExceeded.vue'

export default {
    name: 'FeatureLimitExceeded',
    components: {
        SearchFeatureLimitExceeded
    },
    props: {
        msg: {
            type: String,
            required: true
        },
        feature: {
            type: String,
            required: true
        },
        $toast: {
            type: null,
            default: null
        },
        $auth: {
            type: null,
            default: null
        }
    },
    computed: {
        isTrail () {
            return this.$auth?.user?.is_trail
        }
    },
    mounted () {
        if (!this.isTrail || (this.$toast && ![
            'search', 'file_access'
        ].includes(this.feature))) {
            this.$toast.error(this.msg)
        }
    }
}
