export default function ({ $auth, redirect }) {
    if(!$auth.user.is_tenant_owner && !$auth.user.is_subscribe){
        return redirect('/Expired')
    }

    if(!$auth.user.has_subscriptions){
        return redirect('/Packages')
    }

    if (!$auth.user.is_subscribe) {
        return redirect('/Subscriptions')
    }
}
